<template>
  <div class="columns">
    <div class="column">
      <div class="stick">
        <div class="box">
          <ul>
            <!-- <li v-if="$route.query.type !== 'online' && $route.query.type !== 'offline'">
                        <label v-if="iget.getPoints > piontget" class="error_text">Point not enough</label>
                    </li> -->
            <li>
              <label>Post Date</label>
              <p>{{ form.postdate | moment }}</p>
            </li>
            <li>
              <label>Work Experience</label>
              <p>{{ mapex }}</p>
            </li>
            <li>
              <label>Language</label>
              <p>{{ mappers }}</p>
            </li>
            <li>
              <label>Work Location</label>
              <p>
                {{ maplo }}
              </p>
            </li>
            <li>
              <label>Education Level</label>
              <p>{{ maplevel }}</p>
            </li>
            <li>
              <label>Salary (LAK)</label>
              <p>{{ mapsala }}</p>
            </li>
            <li>
              <label>Point Used</label>
              <p>{{ form.getPoints }}</p>
            </li>
            <li>
              <label>Period (Week)</label>
              <p>{{ form.period }}</p>
            </li>
          </ul>
        </div>
        <div class="button-group">
          <div class="buttons">
            <button class="button info" @click="$router.back()">Back</button>
            <!-- <button class="button info" v-if="!$route.query.id" @click=" $router.push({name: 'upgrade', query: { id: $route.query.id} })">Back</button> -->
            <button v-if="$route.query.id && $route.query.type === 'draft'" @click="preview()" class="button info">
              Preview
            </button>
            <button v-if="$route.query.id && $route.query.type !== 'draft'" @click="perviewJob()" class="button info">
              Preview
            </button>
            <button v-if="!$route.query.id" @click="postDraft()" class="button info">
              Preview
            </button>
          </div>
          <button v-if="$route.query.type === 'draft'" :disabled="!whateverActivatesThisLink"
            :event="whateverActivatesThisLink ? 'click' : ''" @click="postJob()" class="button primary">
            Publish Job
          </button>
          <button v-if="$route.query.type !== 'draft' && $route.query.type === 'expired'
            " @click="postJob()" class="button primary">
            Repost
          </button>
          <button v-if="$route.query.type !== 'draft' && $route.query.type !== 'expired'
            " @click="update()" class="button primary">
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_COMPANY_POFILE } from "../../graphql/Company";
import {
  POST_JOB,
  UPDATE_DRAFT,
  SAVE_DRAFT,
  GET_JOBID,
  UPDATE_JOB,
} from "@/graphql/Job";
import { GET_REUSE } from "@/graphql/Reuse";
import apolloClient_Company from "@/services/apollo-client-company";
import moment from "moment";
export default {
  props: ["iget"],
  insufficient: false,
  data() {
    return {
      mappers: [],
      isLoading: true,
      whateverActivatesThisLink: true,
      company: [],
      piontget: "",
      getexperiences: [],
      experiences: [],
      getlanguages: [],
      languages: [],
      salaries: [],
      getsalaries: [],
      locations: [],
      getlocations: [],
      levels: [],
      getlevels: [],
      mapex: "",
      maplang: [],
      mapsala: "",
      maplo: [],
      maplevel: "",
      form: {
        getPoints: "",
        period: null,
        getTag: [""],
        file: "",
        companyId: "",
        title: "",
        job: [""],
        location: [""],
        experience: "",
        jobLevelId: "",
        level: "",
        language: [""],
        salary: "",
        salaryid: true,
        description: "",
        tagIds: [],
        zone: [],
        coverletter: false,
        apply: false,
        uselink: false,
        link: "",
        applied: [],
        nortification: [],
        notifyExpire: [],
        ispublished: false,
        postdate: null,
        closedate: "",
        expiredate: "",
        details: "",
        staffQTY: "",
        getPointUpgrade: "",
      },
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  async created() {
    await this.GetJobId();
    await this.fetchExperience();
    await this.fetchlanguage();
    await this.fetchSalary();
    await this.fetchLocation();
    await this.fetchLevel();

    const res = await apolloClient_Company.query({
      query: GET_COMPANY_POFILE,
    });
    this.company = res.data.profile;
    this.piontget = this.company.points.point;

    if (this.iget) {
      this.form = this.iget;
      if (this.iget.getPoints > this.piontget) {
        //  alert('ພອຍບໍ່ພໍ')
        this.whateverActivatesThisLink = false;
        if (!this.$route.query.id) {
          const isConfirmed = this.$dialog.alert("Point not enough");
          if (!isConfirmed) return;
        }
      }
    }
  },

  methods: {
    async update() {
      try {
        if (this.$route.query.id) {
          await apolloClient_Company.mutate({
            mutation: UPDATE_JOB,
            variables: {
              jobId: this.$route.query.id,
              employerId: this.form.companyId,
              title: this.form.title,
              description: this.form.description,
              jobFunctionId: this.form.job,
              workingLocationId: this.form.location,
              jobExperienceId: this.form.experience,
              jobLanguageId: this.form.language,
              jobEducationLevelId: this.form.level,
              salayRangeId: this.form.salary,
              isShowSalary: this.form.salaryid,
              tagIds: this.form.tagIds,
              jobZoneIds: [],
              jobLevelId: this.form.jobLevelId,
              openingDate: this.form.postdate,
              closingDate: this.form.closedate,
              expiredDate: this.form.expiredate,
              isApplyUrl: false, // this.form.uselink,
              isCoverLetter: false, //this.form.coverletter,
              isNotApply: this.form.apply,
              isHideApplyButton: this.form.apply,
              appliedEmails: this.form.applied,
              nortificationEmails: this.form.nortification,
              notifyExpire: this.form.notifyExpire,
              isPublished: true, //this.form.ispublished,
              image: "", //this.form.file,
              isURL: this.form.link,
              details: "", //this.form.details,
              staffQTY: this.form.staffQTY,
              aType: "activity",
            },
          });
          //  this.$router.back();
          this.$router.push({
            name: "myjob",
            query: { type: "online", page: 1, perPage: 10, search: "" },
          });
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    perviewJob() {
      if (process.env.NODE_ENV === "production")
        window.open(
          `${process.env.VUE_APP_HTTPS_CLIENT}/job_detail/${this.$route.query.id}/?type=previewJob`,
          "_blank"
        );
      else
        window.open(
          `http://localhost:9090/job_detail/${this.$route.query.id}/?type=previewJob`,
          "_blank"
        );
    },
    async postDraft() {
      const isConfirmed = await this.$dialog.confirm();
      if (!isConfirmed) return;
      await apolloClient_Company.query({
        query: SAVE_DRAFT,
        variables: {
          title: this.form.title,
          description: this.form.description,
          jobFunctionId: this.form.job,
          workingLocationId: this.form.location,
          jobExperienceId: this.form.experience,
          jobLanguageId: this.form.language,
          jobEducationLevelId: this.form.level,
          salayRangeId: this.form.salary,
          isShowSalary: this.form.salaryid,
          tagIds: this.form.tagIds,
          jobZoneIds: this.form.zone,
          jobLevelId: this.form.jobLevelId,
          openingDate: this.form.postdate,
          closingDate: this.form.closedate,
          expiredDate: this.form.expiredate,
          isApplyUrl: false, // this.form.uselink,
          isCoverLetter: false, //this.form.coverletter,
          isNotApply: this.form.apply,
          isHideApplyButton: this.form.apply,
          appliedEmails: this.form.applied,
          nortificationEmails: this.form.nortification,
          notifyExpire:
            this.form.notifyExpire !== null ? this.form.notifyExpire : [],
          isPublished: true,
          image: "", //this.form.file,
          isURL: this.form.link,
          details: "", //this.form.details,
          staffQTY: this.form.staffQTY,
        },
      });
    },
    async GetJobId() {
      try {
        const res = await apolloClient_Company.query({
          query: GET_JOBID,
          variables: {
            type: this.$route.query.type,
            jobId: this.$route.query.id,
          },
        });
        this.isLoading = false;
        this.getjobId = res.data.editJob;
        this.form.job = this.getjobId.jobFunctionId.map((i) => i._id);
        this.form.language = this.getjobId.jobLanguageId.map((i) => i._id);
        this.form.location = this.getjobId.workingLocationId.map((i) => i._id);
        this.form.experience = this.getjobId.jobExperienceId._id;
        this.form.tagIds =
          this.getjobId.tagIds !== null
            ? this.getjobId.tagIds.map((i) => i._id)
            : null;
        this.form.jobLevelId = this.getjobId.jobLevelId._id;
        this.form.level = this.getjobId.jobEducationLevelId._id;
        this.form.salary = this.getjobId.salayRangeId._id;
        this.form.title = this.getjobId.title;
        this.form.apply = this.getjobId.isNotApply;
        this.form.link = this.getjobId.isURL;
        this.form.description = this.getjobId.description;
        this.form.applied = this.getjobId.appliedEmails
          ? this.getjobId.appliedEmails
          : [];
        this.form.salaryid = this.getjobId.isShowSalary;
        this.form.nortification = this.getjobId.nortificationEmails
          ? this.getjobId.nortificationEmails
          : [];
        this.form.notifyExpire =
          this.getjobId.notifyExpire !== null ? this.getjobId.notifyExpire : [];
        this.form.postdate = new Date(this.getjobId.openingDate);
        this.form.closedate = new Date(this.getjobId.closingDate);
        this.form.expiredate = new Date(this.getjobId.expiredDate);
        this.form.companyId = this.getjobId.employerId._id;
        this.d1 = moment(this.form.postdate);
        this.d2 = moment(this.form.expiredate);
        this.days = 24 * 60 * 60 * 1000 * 1;
        this.difference_ms = Math.abs(this.d1 - this.d2);
        this.getDays = Math.floor(this.difference_ms / this.days) + 1;
        this.form.period = Math.floor(this.getDays / 7);

        if (this.form.tagIds && this.form.period) {
          this.form.getPoints = (this.form.period + this.form.period) * 20;
        } else if (this.form.period) {
          this.form.getPoints = this.form.period * 20;
        }
      } catch { }
    },

    async preview() {
      const isConfirmed = await this.$dialog.confirm();
      if (!isConfirmed) return;

      await apolloClient_Company.query({
        query: UPDATE_DRAFT,
        variables: {
          _id: this.$route.query.id,
          title: this.form.title,
          description: this.form.description,
          jobFunctionId: this.form.job,
          workingLocationId: this.form.location,
          jobExperienceId: this.form.experience,
          jobLanguageId: this.form.language,
          jobEducationLevelId: this.form.level,
          salayRangeId: this.form.salary,
          isShowSalary: this.form.salaryid,
          tagIds: this.form.tagIds,
          jobZoneIds: null, //this.form.zone,
          jobLevelId: this.form.jobLevelId,
          openingDate: this.form.postdate,
          closingDate: this.form.closedate,
          expiredDate: this.form.expiredate,
          isApplyUrl: false, // this.form.uselink,
          isCoverLetter: false, //this.form.coverletter,
          isNotApply: this.form.apply,
          isHideApplyButton: "", //this.form.apply,
          appliedEmails: this.form.applied,
          nortificationEmails: this.form.nortification,
          notifyExpire: this.form.notifyExpire,
          isPublished: true,
          image: "", //this.form.file,
          isURL: this.form.link,
          details: "", //this.form.details,
          staffQTY: this.form.staffQTY,
        },
      });

      if (process.env.NODE_ENV === "production")
        window.open(
          `${process.env.VUE_APP_HTTPS_CLIENT}/job_detail/${this.$route.query.id}/?type=previewdraft`,
          "_blank"
        );
      else
        window.open(
          `http://localhost:9090/job_detail/${this.$route.query.id}/?type=previewdraft`,
          "_blank"
        );
    },

    async fetchLevel() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          name: this.levels,
          type: "Degree",
          page: 1,
          perPage: 100,
        },
      });
      this.levels = res.data.getReuse.reuseType;
      this.getlevels = this.levels.find((i) => {
        return i._id === this.form.level;
      });
      this.maplevel = this.getlevels.name;
    },

    async fetchLocation() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          name: this.locations,
          type: "Province",
          page: 1,
          perPage: 1000,
        },
      });

      this.locations = res.data.getReuse.reuseType;
      this.getlocations = this.locations.find((i) => {
        //  return i._id === this.iget.location;
        return this.form.location.includes(i._id);
      });
      this.maplo = this.getlocations.name;
    },
    async fetchSalary() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          name: this.salaries,
          type: "SalaryRange",
          page: 1,
          perPage: 100,
        },
      });
      this.salaries = res.data.getReuse.reuseType;
      this.getsalaries = this.salaries.find((i) => {
        return i._id === this.form.salary;
      });
      this.mapsala = this.getsalaries.name;
    },
    async fetchExperience() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          name: this.experiences,
          type: "JobExperience",
          page: 1,
          perPage: 1000,
        },
      });

      this.experiences = res.data.getReuse.reuseType;
      this.getexperiences = this.experiences.find((i) => {
        return i._id === this.form.experience;
      });
      this.mapex = this.getexperiences.name;
    },
    async fetchlanguage() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          name: this.languages,
          type: "Language",
          page: 1,
          perPage: 100,
        },
      });
      this.languages = res.data.getReuse.reuseType;
      this.getlanguages = this.languages.filter((i) => {
        return this.form.language.includes(i._id);
      });
      //this.maplang = this.getlanguages.name

      this.mappers = this.getlanguages.map((i) => i.name).join(", ");
    },
    async postJob() {
      const isConfirmed = await this.$dialog.confirm();
      if (!isConfirmed) return;
      if (!this.form.nortification.length) {
        await this.$dialog.check("Please fill notification email...");
        this.$router.push({
          name: "email",
          query: { id: this.$route.query.id, type: this.$route.query.type },
        });
        return;
      }
      await apolloClient_Company
        .query({
          query: POST_JOB,
          variables: {
            draftId: this.$route.query.id,
            title: this.form.title,
            description: this.form.description,
            jobFunctionId: this.form.job,
            workingLocationId: this.form.location,
            jobExperienceId: this.form.experience,
            jobLanguageId: this.form.language,
            jobEducationLevelId: this.form.level,
            salayRangeId: this.form.salary,
            isShowSalary: this.form.salaryid,
            tagIds: this.form.tagIds,
            jobZoneIds: [],
            jobLevelId: this.form.jobLevelId,
            openingDate: this.form.postdate,
            closingDate: this.form.closedate,
            expiredDate: this.form.expiredate,
            isApplyUrl: this.form.uselink,
            isCoverLetter: false,
            isNotApply: this.form.apply,
            isHideApplyButton: this.form.apply,
            appliedEmails: this.form.applied,
            nortificationEmails: this.form.nortification,
            notifyExpire:
              this.form.notifyExpire !== null ? this.form.notifyExpire : [],
            isPublished: true,
            //image: this.form.file,
            isURL: this.form.link,
            //details: this.form.details,
            staffQTY: this.form.staffQTY,
          },
        })
        .then(async (res) => {
          this.$router.push({
            name: "myjob",
            query: { type: "online", page: 1, perPage: 10, search: "" },
          });
        })
        .catch(async (er) => {
          await this.$dialog.check(er);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.error_text {
  color: red;
}

.box-header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  .box-title {
    font-weight: 700;
    font-size: 20px;
    color: var(--text-color);
    margin-right: 10px;
  }
}

ul {
  li {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    padding: 8px 0;
    border-bottom: 1px solid var(--border-color);

    &:last-child {
      border-bottom: none;
    }

    label {
      font-size: 16px;
      color: var(--text-color);
      font-weight: 600;
    }

    p {
      font-weight: 500;
      color: var(--text-color);
      font-size: 16px;
      line-height: 1.7;
      text-align: right;
    }

    h3 {
      font-weight: 700;
      color: var(--primary-color);
    }
  }
}

.sticky {
  position: sticky;
  top: 20px;
}
</style>
